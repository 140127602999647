<template>
  <tr>
    <td class="align-middle text-large">
      <SurveyEmoti :score="customer.lastSurvey && customer.lastSurvey.rating" />
    </td>
    <td class="align-middle text-muted">
      {{ customer.lastActivityDate | created }}
    </td>
    <td class="align-middle text-capitalize">
      {{ customer.name || 'Valued customer' }}
    </td>
    <td class="text-large align-middle text-center">
      <SurveyFeedback
        :score="customer.lastSurvey && customer.lastSurvey.rating"
        :feedback="customer.lastSurvey && customer.lastSurvey.feedback"
      />
    </td>
    <td class="text-center text-large" v-b-tooltip.click.hover="customer.email">
      <CheckOrX :value="customer.email" />
    </td>
    <td class="text-center text-large" v-b-tooltip.click.hover :title="customer.phone | phone">
      <CheckOrX :value="customer.phone" />
    </td>
    <td class="align-middle" v-if="selectActiveCompany._id !== 'ALL_COMPANIES'">
      <CustomerTags :customerTags="tags" :customerId="customer._id" />
    </td>
    <td v-if="loyaltyEnabled" class="align-middle">
      {{ (customer.loyalty && customer.loyalty.points) || 0 }}
    </td>
  </tr>
</template>

<script>
// TODO: on v3 refactor these components into a generic component library
import CheckOrX from '@/components/Modules/Customer/CustomersTableCard/CustomersTableUtil/CheckOrX'
import SurveyEmoti from '@/components/Modules/Customer/CustomersTableCard/CustomersTableUtil/SurveyEmoti'
import CustomerTags from '@/components/Modules/Customer/CustomersTableCard/CustomersTableUtil/CustomerTags'
import SurveyFeedback from '@/components/Modules/Customer/CustomersTableCard/CustomersTableUtil/SurveyFeedback'
import { createNamespacedHelpers } from 'vuex'
import { format as formatDate } from 'date-fns'

const CompanyModule = createNamespacedHelpers('company')
const CustomerModule = createNamespacedHelpers('customer')
const TimelineModule = createNamespacedHelpers('timeline')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'CustomerTableRow',
  props: ['customerData', 'light'],
  filters: {
    created(date) {
      return formatDate(date, 'MM/DD/YY hh:mm a')
    },
  },
  components: {
    CheckOrX,
    SurveyEmoti,
    SurveyFeedback,
    CustomerTags,
  },
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...CompanyModule.mapGetters(['loyaltyEnabled']),
    ...CustomerModule.mapState(['listFilters']),
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds', 'selectActiveLocations']),
    tags() {
      const tags = (this.customer.tags || {})[this.selectActiveCompany._id] || []
      return tags.filter(Boolean)
    },
    customer() {
      const customer = { ...this.customerData }

      if (customer.loyaltyCache) {
        const { companies } = customer.loyaltyCache

        if (companies && companies[this.selectActiveCompany._id]) {
          customer.loyalty = companies[this.selectActiveCompany._id]
        }
      }

      return customer
    },
  },
  methods: {
    ...CustomerModule.mapActions(['deleteCustomer']),
    ...TimelineModule.mapActions(['initializeTimeline']),
    ...ConversationModuleV2.mapActions(['setSpecificConversation']),
    viewFeedback(feedback) {
      alert(feedback)
    },
    confirmDelete(customer) {
      if (confirm("Are you sure you'd like to delete this customer?")) {
        this.deleteCustomer(customer)
      }
    },
  },
}
</script>
