<template>
  <div class="text-preview-wrapper" :style="{ height: `${previewHeight}px` }">
    <div id="wrapper"></div>
    <div id="devicewrap">
      <div class="marvel-device iphone-x" style="scale: 1">
        <div class="notch">
          <div class="camera"></div>
          <div class="speaker"></div>
        </div>
        <div class="top-bar"></div>
        <div class="sleep"></div>
        <div class="bottom-bar"></div>
        <div class="volume"></div>
        <div class="overflow">
          <div class="shadow shadow--tr"></div>
          <div class="shadow shadow--tl"></div>
          <div class="shadow shadow--br"></div>
          <div class="shadow shadow--bl"></div>
        </div>
        <div class="inner-shadow"></div>
        <div class="screen">
          <div class="carrier">
            <div class="d-flex justify-content-between">
              <span class="ml-3 pl-2">2:50pm</span>
              <span class="mr-3 pr-3">
                <i class="fa fa-signal mr-2"></i>
                <i class="fa fa-stop"></i>
              </span>
            </div>
          </div>
          <div class="text-title mt-3">
            <span class="left">
              <i class="fa fa-chevron-left"></i>
            </span>
            <span class="center">555-555-5555</span>
          </div>
          <div class="bubble bubble-left active">
            <span class="message-text">
              <p v-html="parsedMessageBody || 'Message Preview...'"></p>
              <p v-if="parsedPromo" v-html="parsedPromo"></p>
              Sent from {{ name }} <br />Reply STOP to opt-out
            </span>
          </div>
          <div
            class="bubble bubble-img bubble-left active p-0"
            v-for="(media, idx) in messageMedia"
            :key="idx"
          >
            <img class="rounded" :alt="'Loading Image'" :src="media.url" />
          </div>
        </div>
        <div class="home"></div>
        <div class="bottom-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { templatize } from '@/lib/template'
import { TEMPLATE_CHUNK_TYPES } from '@/lib/modules/campaignSetups'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'MessagePreview',
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup', 'selectLocation']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    parsedPromo() {
      if (!this.selectCampaignSetup.promo) return undefined
      return 'Redeem: <span class="text-primary">ovtn.io/redeem</span>'
    },
    parsedMessageBody() {
      let message = this.selectCampaignSetup.templateChunks[0].template.replace(/\n/g, '<br>\n')
      return templatize({
        body: message,
        customer: { name: 'Jane Doe' },
      })
    },
    previewHeight() {
      let height = 505
      return Math.min(height, 533)
    },
    messageMedia() {
      return this.selectCampaignSetup.templateChunks.filter(
        (tc) => tc.type === TEMPLATE_CHUNK_TYPES.MEDIA
      )
    },
    name() {
      return this.selectActiveCompany._id === 'ALL_COMPANIES'
        ? this.selectOrganization?.name
        : this.selectActiveCompany.name
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions(['setCampaignSetup', 'getDefaultLocation']),
  },
  mounted() {
    if (!this.selectCampaignSetup.templateChunks[0].template) {
      this.setCampaignSetup({
        ...this.selectCampaignSetup,
        templateChunks: [
          { type: 'text', template: '' },
          ...this.selectCampaignSetup.templateChunks.filter(
            (tc) => tc.type === TEMPLATE_CHUNK_TYPES.MEDIA
          ),
        ],
      })
    }

    this.getDefaultLocation()
  },
}
</script>

<style scoped lang="scss">
.text-title .center {
  font-size: 13px;
}
.text-preview-wrapper {
  position: relative;
  top: 43px;
  overflow: hidden;
  .screen {
    border-radius: 10% 10% 0% 0%;
    overflow-y: scroll;
    height: 480px;
    .bubble {
      width: 85%;
    }

    .bubble-img {
      width: 42.5%;
    }
  }
}
</style>
