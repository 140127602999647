<template>
  <MultiSelect
    taggable
    multiple
    hideCaret
    reposition
    hideSelected
    label="name"
    track-by="_id"
    v-model="tagsFilters"
    :searchable="false"
    class="tag-filters"
    id="customer-filters"
    :options="selectCompanyTags"
    :show-labels="false"
    openDirection="below"
    tag-placeholder="Add"
    :close-on-select="false"
    @close="updateTags"
    @remove="updateTags"
    :showNoResults="false"
    :internal-search="true"
  >
    <template #placeholder>
      <span class="text-secondary"> <span class="fas fa-hashtag"></span> All tags </span>
    </template>
  </MultiSelect>
</template>

<script>
import MultiSelect from '@/components/Common/MultiSelect'
import { createNamespacedHelpers } from 'vuex'

const TagModule = createNamespacedHelpers('tag')

export default {
  name: 'AudienceTagFilter',
  props: {
    initialTagIds: {
      type: Array,
    },
  },
  components: {
    MultiSelect,
  },
  data: () => ({
    tagsFilters: [],
  }),
  mounted() {
    // add logic to prepopulate the tags
    if (this.initialTagIds) {
      this.tagsFilters = this.selectCompanyTags.filter((ct) =>
        this.initialTagIds.includes(ct._id.toString())
      )
    }
  },
  computed: {
    ...TagModule.mapGetters(['selectCompanyTags']),
  },
  methods: {
    updateTags(tags) {
      this.$emit('update', this.tagsFilters)
    },
  },
  watch: {
    selectCompanyTags: {
      immediate: true,
      handler() {
        if (this.initialTagIds) {
          this.tagsFilters = this.selectCompanyTags.filter((ct) =>
            this.initialTagIds.includes(ct._id.toString())
          )
        }
      },
    },
  },
}
</script>
