<template>
  <b-modal
    ref="tcpaModal"
    id="tcpaModal"
    hide-footer
    hide-header
    body-class="p-0"
    centered
    no-close-on-backdrop
  >
    <div class="modal-card card">
      <!-- header -->
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title">Campaign Completion After Blackout Hours</h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>

      <!-- body -->
      <div class="card-body" style="max-height: 650px">
        <div class="mb-3">
          Due to the size of the audience, this campaign could extend past allowed SMS sending
          hours. Please select an option for handling messages after sending hours.
          <a
            href="https://intercom.help/ovation-bd4a0805515d/en/articles/9095473-sms-sending-hours"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
        <div class="mx-3">
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="radio"
              id="pauseRadio"
              value="pause"
              v-model="selection"
            />
            <label class="form-check-label" for="pauseRadio">
              <span class="font-weight-bold"> Pause </span> - Remaining messages will be queued and
              will resume sending the following business day.
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              id="terminateRadio"
              value="terminate"
              v-model="selection"
            />

            <label class="form-check-label" for="terminateRadio">
              <span class="font-weight-bold"> Terminate </span> - Remaining messages will not be
              sent.
            </label>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="card-footer">
        <div class="d-flex">
          <button class="btn mr-2 ml-auto" @click="close()">Cancel</button>
          <button class="btn btn-primary" :disabled="!validSelection" @click="save">Save</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'CampaignSetupsTCPAModal',
  data: () => ({
    selection: 'pause',
  }),
  mounted() {
    if (this.selectCampaignSetup.stopSendingOutsideTCPAWindow) {
      this.selection = 'terminate'
    }
  },
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    validSelection() {
      return !!this.selection
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions(['setCampaignSetup']),
    save() {
      this.setStopSendingOutsideTCPAWindow(this.selection)
    },
    close() {
      this.setStopSendingOutsideTCPAWindow(
        this.selectCampaignSetup.stopSendingOutsideTCPAWindow ? 'terminate' : 'pause'
      )
    },
    setStopSendingOutsideTCPAWindow(val) {
      this.setCampaignSetup({
        ...this.selectCampaignSetup,
        stopSendingOutsideTCPAWindow: val === 'terminate' ? true : false,
      })
      this.$emit('save')
      this.$refs.tcpaModal.hide()
    },
  },
}
</script>
