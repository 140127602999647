<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <h2 class="text-secondary mt-2 mb-1">
          <span class="text-primary">
            <animated-number
              :value="selectCount"
              :formatValue="parseInt"
              :update="updating"
              :complete="doneUpdating"
              :duration="500"
            />
          </span>
          customers match your audience criteria.
        </h2>
        <span class="text-muted">Adjust your criteria below to narrow your audience.</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <AudienceSelectionCard
          :limit="3"
          :show-string-filter="false"
          :campaignSource="true"
          :show-customer-count="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import AudienceSelectionCard from './AudienceSelectionCard'
import { createNamespacedHelpers } from 'vuex'

const CustomerModule = createNamespacedHelpers('customer')
const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CustomFilterModule = createNamespacedHelpers('customFilter')

export default {
  name: 'CreateCampaignSetupsAudience',
  components: {
    AnimatedNumber,
    AudienceSelectionCard,
  },
  data: () => {
    return {
      initialized: false,
      isCounting: false,
    }
  },
  mounted() {
    this.setSkipCount(0)

    if (
      !this.selectCampaignSetup ||
      (!this.selectCampaignSetup._id && !this.selectCampaignSetup.duplicate)
    ) {
      this.setListFilters({})
    }

    this.initialized = true
  },
  computed: {
    ...CustomFilterModule. mapGetters(['selectCount']),
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
  },
  methods: {
    ...CustomerModule.mapActions(['setSkipCount', 'setListFilters']),
    updating() {
      this.isCounting = true
    },
    doneUpdating() {
      this.isCounting = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
