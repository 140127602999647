<template>
  <MainContent>
    <div class="container" v-if="selectActiveCompany" :key="selectActiveCompany._id">
      <CreateCampaignSetupsView v-if="showOrgCampaigns" />
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-12">
            <CreateCampaignHeader @saveCampaignDraft="saveDraftCampaign" />
          </div>
        </div>
        <div>
          <CreateCampaignWrapper ref="campaignWrapper" />
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import CreateCampaignHeader from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignHeader'
import CreateCampaignWrapper from '@/components/Modules/Campaign/CreateCampaign/CreateCampaignWrapper'
import CreateCampaignSetupsView from '@/components/Modules/CampaignSetups/CreateCampaignSetups/CreateCampaignSetupsView'
import { createNamespacedHelpers } from 'vuex'

const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CreateCampaignPage',
  components: {
    MainContent,
    CreateCampaignHeader,
    CreateCampaignWrapper,
    CreateCampaignSetupsView,
  },
  computed: {
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    showOrgCampaigns() {
      return this.selectActiveCompany?._id === 'ALL_COMPANIES' || this.isEnabled('HIDE_LEGACY_CAMPAIGNS')
    },
  },
  methods: {
    saveDraftCampaign() {
      return this.$refs.campaignWrapper.saveOrUpdateCampaign()
    },
  },
}
</script>

<style scoped lang="scss"></style>
