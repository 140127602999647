<template>
  <div>
    <div v-show="initialized">
      <div class="card">
        <AudienceFilters
          @previewLoaded="() => (this.previewLoaded = true)"
          @filtersInitialized="() => (this.filtersInitialized = true)"
        />
        <AudienceTable v-if="!selectIsLoading" />
        <b-skeleton-table :columns="3" :rows="3" class="text-center" v-else />
      </div>
    </div>
    <div v-if="!initialized">
      <b-skeleton animation="fade" width="100%" height="200px" />
      <b-skeleton-table
        :columns="5"
        :rows="3"
        class="text-center"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
  </div>
</template>

<script>
import AudienceFilters from './AudienceFilters'
import AudienceTable from './AudienceTable'
import { createNamespacedHelpers } from 'vuex'

const CustomFilterModule = createNamespacedHelpers('customFilter')

export default {
  name: 'AudienceSelectionCard',
  components: {
    AudienceFilters,
    AudienceTable,
  },
  data: () => ({
    previewLoaded: false,
    filtersInitialized: false,
  }),
  computed: {
    ...CustomFilterModule.mapGetters(['selectIsLoading', 'selectCustomFilter']),
    initialized() {
      return this.previewLoaded && this.filtersInitialized
    },
  },
  methods: {
    ...CustomFilterModule.mapActions(['fetchAudiencePreview']),
  },
}
</script>
