<template>
  <b-modal ref="modal" id="createPromoModal" hide-footer hide-header size="xl" body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title" id="exampleModalCenterTitle">New Offer</h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div class="row">
          <div class="col-12">
            <PromoCreationForm :modal="true" @saved="onCreatePromo" />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PromoCreationForm from '@/components/Modules/PromoV2/PromoCreation/Components/PromoCreationForm'

export default {
  name: 'CreatePromoModal',
  components: {
    PromoCreationForm,
  },
  data: () => ({}),
  methods: {
    onCreatePromo(promo) {
      this.$emit('added', promo)
      this.close()
    },
    open() {
      this.$refs.modal.show()
    },
    close() {
      this.$refs.modal.hide()
    },
  },
}
</script>
