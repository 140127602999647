<template>
  <div>
    <!-- Add Promo Modal -->
    <PromoCreationModal v-if="includeModal" @added="addedPromo" ref="promoCreationModal" />

    <!-- Button to add if no promos exist -->
    <a
      class="btn btn-white mb-0 d-flex align-items-center"
      :class="large ? 'btn-lg' : 'btn-sm'"
      href="javascript:;"
      v-if="!selectPromos.length"
      @click="addPromo"
    >
      <i class="fe fe-plus mr-1" :class="large && 'icon-large'"></i> Offer
    </a>

    <!-- Promos Dropdown -->
    <b-dropdown
      :variant="selectedPromo ? 'primary' : 'white'"
      :size="large ? 'lg' : 'sm'"
      v-if="selectPromos.length"
      class="hide-caret"
    >
      <template slot="button-content">
        <span
          class="text-capitalize d-flex align-items-center"
          :class="large ? '' : 'voucher-span'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="20"
            height="20"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512; position: relative"
            xml:space="preserve"
            :class="[large && ['icon-large', 'mr-2'], selectedPromo && 'mr-1']"
          >
            <g>
              <g>
                <path
                  :fill="selectedPromo ? '#fff' : '#283e59'"
                  d="M497.231,211.692c8.157,0,14.769-6.613,14.769-14.769v-78.769c0-8.157-6.613-14.769-14.769-14.769H14.769
			C6.613,103.385,0,109.997,0,118.154v78.769c0,8.157,6.613,14.769,14.769,14.769c24.431,0,44.308,19.876,44.308,44.308
			s-19.876,44.308-44.308,44.308C6.613,300.308,0,306.92,0,315.077v78.769c0,8.157,6.613,14.769,14.769,14.769h482.462
			c8.157,0,14.769-6.613,14.769-14.769v-78.769c0-8.157-6.613-14.769-14.769-14.769c-24.431,0-44.308-19.876-44.308-44.308
			S472.799,211.692,497.231,211.692z M482.462,328.362v50.715H172.308v-44.308c0-8.157-6.613-14.769-14.769-14.769
			s-14.769,6.613-14.769,14.769v44.308H29.538v-50.715c33.665-6.862,59.077-36.701,59.077-72.362s-25.412-65.501-59.077-72.362
			v-50.715h113.231v44.308c0,8.157,6.613,14.769,14.769,14.769s14.769-6.613,14.769-14.769v-44.308h310.154v50.715
			c-33.665,6.862-59.077,36.701-59.077,72.362S448.797,321.501,482.462,328.362z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  :fill="selectedPromo ? '#fff' : '#283e59'"
                  d="M157.538,221.538c-8.157,0-14.769,6.613-14.769,14.769v39.385c0,8.157,6.613,14.769,14.769,14.769
			s14.769-6.613,14.769-14.769v-39.385C172.308,228.151,165.695,221.538,157.538,221.538z"
                />
              </g>
            </g>
          </svg>
          {{
            selectedPromo
              ? selectedPromo.nickname || selectedPromo.title
              : (large ? 'No Offer' : '') | truncate(20)
          }}
        </span>
      </template>
      <b-dropdown-item
        v-for="promo in filteredPromos"
        :key="promo._id"
        class="dropdown-item text-capitalize"
        :class="large && 'h4'"
        @click="setPromo(promo)"
      >
        {{ promo.nickname || promo.title | truncate(50) }}
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="addPromo">Create Offer</b-dropdown-item>
      <div v-if="selectedPromo" class="dropdown-divider"></div>
      <b-dropdown-item class="text-warning" @click="reset()" v-if="selectedPromo">
        No Offer
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import PromoCreationModal from '@/components/Modals/PromoCreationModal'
import { createNamespacedHelpers } from 'vuex'

const PromoModuleV2 = createNamespacedHelpers('promoV2')
const ConversationModule = createNamespacedHelpers('conversation')
const CustomQuestionModule = createNamespacedHelpers('customQuestion')
const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'PromoDropdown',
  props: {
    source: {
      type: String,
      default: '',
    },
    includeModal: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    includeUniquePromos: {
      type: Boolean,
      default: true,
    },
    parentComponent: {
      type: String,
      default: '',
    },
  },
  components: { PromoCreationModal },
  data: () => ({
    loadModal: false,
    selectedPromo: undefined,
  }),
  async mounted() {
    this.selectPromo.source = this.source
    await this.fetchPromos()

    if (this.parentComponent === 'campaignSetup' && this.selectCampaignSetup.promo) {
      this.setPromo(this.selectPromos.find((promo) => this.selectCampaignSetup.promo === promo._id))
    } else if (this.source === 'customQuestion' && this.settings.promo) {
      this.selectedPromo = this.selectPromos.find((promo) => this.settings.promo._id === promo._id)
      this.$emit('updatePromo', this.selectedPromo)
    }

    this.$root.$on('added-promo', this.addedPromo)
  },
  destroyed() {
    this.resetPromo()
    this.$root.$off('added-promo', this.addedPromo)
  },
  computed: {
    ...PromoModuleV2.mapGetters(['selectPromos', 'selectPromo']),
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    ...ConversationModule.mapGetters(['activeConversation']),
    ...CustomQuestionModule.mapGetters(['settings']),
    filteredPromos() {
      let self = this
      return this.selectPromos.filter((promo) => {
        return self.activeConversation ? promo.company == self.activeConversation.company : true
      })
    },
  },
  methods: {
    ...PromoModuleV2.mapActions(['fetchPromos', 'resetPromo']),
    addPromo() {
      if (this.source === 'customQuestion') {
        this.$router.push('/promos')
      } else {
        this.loadModal = true
        this.resetPromo()
        this.$root.$emit('add-promo')
        this.selectPromo.source = this.source
        if (this.includeModal) this.$refs.promoCreationModal.open()
      }
    },
    setPromo(promo) {
      this.selectedPromo = promo
      this.$emit('selected', promo)
    },
    reset() {
      this.selectedPromo = undefined
      this.$emit('reset')
    },
    async addedPromo(promo) {
      this.selectPromo.source = this.source
      this.fetchPromos()
      this.setPromo(promo)
    },
  },
}
</script>

<style scoped lang="scss">
a {
  text-transform: capitalize;
}
.dropdown-item {
  font-size: 14px;
}
.icon-large {
  font-size: 1.4em;
}
.voucher-span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.voucher-span img {
  width: 18px;
  height: 18px;
}
</style>
