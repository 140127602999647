<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="header-title">
            {{ selectCampaignSetup._id ? 'Edit' : 'Create' }}
            {{ isOrg ? 'Organization' : '' }} Campaign
          </h1>
        </div>
        <div v-if="isDraft">
          <a
            @click="saveCampaignSetupDraft"
            href="javascript:;"
            class="btn btn-primary mr-3"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
            >Save draft</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CAMPAIGN_SETUPS_STATUSES } from '@/lib/modules/campaignSetups'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CreateCampaignSetupsHeader',
  data: () => ({
    isLoading: false, // TODO: implement loading, Tudip team built this and never wired it up
  }),
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    isDraft() {
      return (
        (!this.selectCampaignSetup._id && this.selectCampaignSetup.templateChunks?.length) ||
        this.selectCampaignSetup.status === CAMPAIGN_SETUPS_STATUSES.DRAFT
      )
    },
    isOrg() {
      return this.selectActiveCompany._id === 'ALL_COMPANIES'
    },
  },
  methods: {
    saveCampaignSetupDraft() {
      this.$emit('saveCampaignSetupDraft')
    },
  },
}
</script>

<style scoped lang="scss"></style>
