<template>
  <b-card no-body :class="{ active }">
    <b-card-header header-tag="header" role="tab">
      <div class="col-auto ml-0 pl-0">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-lg pl-0"
            type="button"
            :disabled="active || !enabled"
            @click="setStep(step)"
          >
            <span class="badge badge-soft-secondary mr-2">{{ stepNumber }}</span>
            <slot name="title"></slot>
          </button>
        </h2>
      </div>
      <div class="col col-12 mr-0 pr-0 d-flex justify-content-end" v-if="active">
        <button
          v-if="showDraftBtn"
          class="btn btn-outline-white mr-3"
          type="button"
          :disabled="isStepInvalid"
          @click="saveOrUpdateCampaignSetup"
        >
          Save Draft
        </button>
        <slot name="next-button">
          <button
            v-if="step !== 'summary'"
            class="btn btn-next"
            type="button"
            :disabled="isStepInvalid"
            @click="setStep('next')"
          >
            Next
          </button>
        </slot>
      </div>
    </b-card-header>

    <b-collapse :id="`${step}Accordion`" :visible="active" :accordion="accordion" role="tabpanel">
      <b-card-body>
        <slot name="body"></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { validationRules } from '@/lib/campaign-validation'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'CreateCampaignSetupsStep',
  props: {
    step: {
      type: String,
      required: true,
    },
    stepNumber: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    accordion: {
      type: String,
      default: 'campaignAccordion',
    },
  },
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    isStepInvalid() {
      return !!validationRules[this.step](this.selectCampaignSetup, this.$store)
    },
    showDraftBtn() {
      return (
        /message|schedule/.test(this.step) &&
        (!this.selectCampaignSetup._id || this.selectCampaignSetup.status === 'draft')
      )
    },
  },
  methods: {
    saveOrUpdateCampaignSetup() {
      this.$emit('saveOrUpdateCampaignSetup')
    },
    setStep(step) {
      this.$emit('setStep', step)
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  .badge {
    border-radius: 100px;
    width: 24.5px;
    height: 24.5px;
    padding: 5px;
    border: 1px solid #fff;
  }

  &.active {
    .card-header {
      border-color: #2c7be5;
      background-color: #2c7be5;
      .text-muted {
        color: #fff;
      }
      button {
        opacity: 1;
        color: #fff;

        &.btn-next {
          color: #2c7be5;
          background-color: #fff;

          &:disabled {
            opacity: 0.7;
          }
        }
        &.btn-outline-white {
          &:disabled {
            opacity: 0.7;
          }
        }

        &:hover:not(:disabled) {
          color: #1657af;
        }

        .badge {
          color: #fff;
          background-color: #2c7be5;
        }
      }
    }
  }
}
</style>
