<template>
  <div :class="{ 'force-show-placeholder': currentActions.length }">
    <popper
      ref="popperRef"
      class="action-popper"
      trigger="click"
      @show="initialize()"
      @hide="applyAndUpdate"
      :options="popperOptions"
    >
      <div class="popper">
        <div class="p-3" v-show="isCurrentStatus(selectFilterStatusesV2.CHOOSE_ACTION)">
          <div class="row mt-0">
            <div class="col">
              <h6 class="header-pretitle mb--4 mt-3 text-left">Behavior filters</h6>
            </div>
            <div class="col text-right">
              <button @click="reset()" class="btn btn-outline-secondary btn-sm mb--2">Reset</button>
            </div>
          </div>
          <hr class="mb-4" />
          <div
            v-for="(currentAction, currentActionIndex) in allCurrentActions"
            :key="currentAction.id"
          >
            <AudienceActionForm
              :actionIndex="currentActionIndex"
              :action="currentAction"
              :activeActionLabel="getActionLabel(currentAction, true)"
              @updateAction="updateAction"
              @removeAction="removeAction"
            />
            <div
              class="d-flex align-items-center my-3"
              v-if="currentActionIndex !== allCurrentActions.length - 1"
            >
              <hr class="w-100" />
              <span class="badge badge-soft-primary">AND</span>
              <hr class="w-100" />
            </div>
          </div>
          <hr class="w-100 mt-4" />
          <div class="text-left mt-4 mb-2">
            <a
              class="btn btn-sm btn-outline-secondary btn-rounded-circle"
              href="javascript:;"
              @click="addNewAction"
            >
              <span class="fe fe-plus text-black"></span>
            </a>
            <span class="text-muted ml-3">Add behavior</span>
          </div>
        </div>
        <AudienceActionFilterItemList
          type="campaign"
          :items="selectCampaignSetups"
          @input="selectCampaign"
          v-show="isCurrentStatus(selectFilterStatusesV2.CHOOSE_CAMPAIGN)"
        />
        <AudienceActionFilterItemList
          type="source"
          :items="sources"
          @input="selectSource"
          v-show="isCurrentStatus(selectFilterStatusesV2.CHOOSE_SOURCE)"
        />
        <AudienceActionFilterItemList
          type="offer"
          :items="selectPromos"
          @input="selectPromo"
          v-show="isCurrentStatus(selectFilterStatusesV2.CHOOSE_PROMO)"
        />
      </div>

      <multiselect
        slot="reference"
        :style="{
          width: currentActions && currentActions.length ? 'auto' : '122px',
        }"
        selectLabel
        :hideSelected="false"
        :close-on-select="false"
        :multiple="true"
        :searchable="false"
        :showLabels="false"
        :limit="1"
        :limitText="(count) => `+${count} more`"
        :options="[]"
        :custom-label="getActionLabel"
        v-model="currentActions"
        @remove="applyAndUpdate"
        track-by="id"
      >
        <template slot="placeholder">
          <span class="text-secondary"> <i class="fe fe-target"></i> Any behavior </span>
        </template>
      </multiselect>
    </popper>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Popper from 'vue-popperjs'
import AudienceActionFilterItemList from '../Components/AudienceActionFilterItemList'
import AudienceActionForm from '../Components/AudienceActionForm'
import { map } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CustomFilterModule = createNamespacedHelpers('customFilter')
const SurveyModule = createNamespacedHelpers('survey')
const PromoModuleV2 = createNamespacedHelpers('promoV2')
const ActionFilterModule = createNamespacedHelpers('actionFilter')

export default {
  name: 'AudienceActionFilter',
  components: {
    AudienceActionFilterItemList,
    AudienceActionForm,
    Multiselect,
    Popper,
  },
  props: {
    initialActions: {
      type: Array,
    },
  },
  data: () => ({
    status: '',
    selectedActionIndex: -1,
    currentActions: [],
    allCurrentActions: [], // same currentActions but Holds 1st default action when no action is selected
    popperOptions: {
      placement: 'bottom',
      modifiers: {
        offset: { offset: '0,6px' },
        flip: { enabled: false },
        gpuAcceleration: true,
      },
    },
  }),
  async mounted() {
    this.allCurrentActions.push(this.getDefaultAction())
    await Promise.all([this.fetchCampaignSetups(), this.fetchPromos()])
    this.initialize()
  },
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetups']),
    ...CustomFilterModule.mapGetters(['selectCustomFilter']),
    ...SurveyModule.mapGetters(['selectSurveySources']),
    ...PromoModuleV2.mapGetters(['selectPromos']),
    ...ActionFilterModule.mapGetters([
      'selectAnalyticEventTypesV2',
      'selectAnalyticEventsV2',
      'selectFilterKeys',
      'selectFilterStatusesV2',
    ]),
    sources() {
      return map(this.selectSurveySources, (title, _id) => ({
        _id,
        title,
      }))
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions(['fetchCampaignSetups']),
    ...PromoModuleV2.mapActions(['fetchPromos']),
    getActionLabel(action, isShortLabel) {
      if (action.eventType == this.selectAnalyticEventTypesV2.CHOOSE_ACTION) {
        return 'Choose action'
      }
      let label = ''
      const eventType = this.getAnalyticEventType(action.eventType)
      const campaign = this.getCampaignById(action.campaignId)
      const sourceTitle = this.selectSurveySources[action.source]
      const promo = this.getPromoById(action.promoId)
      switch (eventType.type) {
        case this.selectAnalyticEventTypesV2.SENT_CAMPAIGN:
          label = `${campaign ? campaign.title.toLowerCase() : 'any campaign'}`
          break
        case this.selectAnalyticEventTypesV2.COME_FROM:
          label = `${isShortLabel ? 'C' : 'c'}ome from ${
            sourceTitle ? sourceTitle.toLowerCase() : 'any source'
          }`
          break
        case this.selectAnalyticEventTypesV2.REDEEM_PROMO:
          label = `${
            promo ? promo.nickname?.toLowerCase() || promo.title?.toLowerCase() : 'any offer'
          }`
          break
        default:
          label = isShortLabel ? eventType.name : `${eventType.name.toLowerCase()}`
      }
      return `${this.getLabelPrefix(action, eventType, isShortLabel)}${label}`
    },
    getLabelPrefix(action, eventType, isShortLabel) {
      const prefix = action.did ? 'Did ' : 'Did not '
      switch (eventType.type) {
        case this.selectAnalyticEventTypesV2.SENT_CAMPAIGN:
          return isShortLabel ? 'Receive ' : `${action.did ? 'Received ' : `${prefix}receive `}`
        case this.selectAnalyticEventTypesV2.REDEEM_PROMO:
          return isShortLabel ? 'Redeem ' : `${action.did ? 'Redeemed ' : `${prefix}redeem `}`
        default:
          if (isShortLabel) return ''
          return prefix
      }
    },
    getCampaignById(id) {
      return this.selectCampaignSetups.find((cs) => cs._id === id)
    },
    getPromoById(id) {
      return this.selectPromos.find((promo) => promo._id === id)
    },
    initialize() {
      this.status = this.selectFilterStatusesV2.CHOOSE_ACTION
      if (this.initialActions?.length) {
        this.currentActions = [
          ...this.initialActions.map((action, index) => ({
            ...action,
            id: action.eventType + index,
          })),
        ]
      }
    },
    reset() {
      this.currentActions = []
      this.applyFilters()
      this.$refs.popperRef.doClose()
    },
    async applyFilters() {
      // match with the current actions OR have a default action in allCurrentActions
      if (this.currentActions.length == 0) {
        this.allCurrentActions = []
        this.allCurrentActions.push(this.getDefaultAction())
      } else {
        this.allCurrentActions = this.currentActions
      }
    },
    async applyAndUpdate() {
      await this.applyFilters()
      this.$emit('updateActions', this.currentActions)
    },
    getDefaultAction() {
      return {
        id: this.selectAnalyticEventTypesV2.CHOOSE_ACTION + this.allCurrentActions.length,
        did: true,
        eventType: this.selectAnalyticEventTypesV2.CHOOSE_ACTION,
      }
    },
    addNewAction() {
      this.currentActions.push({
        id: this.selectAnalyticEventTypesV2.LEFT_SURVEY + this.allCurrentActions.length,
        did: true,
        eventType: this.selectAnalyticEventTypesV2.LEFT_SURVEY,
      })
      this.applyFilters()
    },
    updateAction(index, key, value) {
      this.selectedActionIndex = index
      const currentAction = { ...this.allCurrentActions[index] }
      switch (true) {
        case key === this.selectFilterKeys.EVENT &&
          value === this.selectAnalyticEventTypesV2.SENT_CAMPAIGN:
          this.status = this.selectFilterStatusesV2.CHOOSE_CAMPAIGN
          break
        case key === this.selectFilterKeys.EVENT &&
          value === this.selectAnalyticEventTypesV2.REDEEM_PROMO:
          this.status = this.selectFilterStatusesV2.CHOOSE_PROMO
          break
        case key === this.selectFilterKeys.EVENT &&
          value === this.selectAnalyticEventTypesV2.COME_FROM:
          this.status = this.selectFilterStatusesV2.CHOOSE_SOURCE
          break
        case key === this.selectFilterKeys.CAMPAIGN:
          delete currentAction[this.selectFilterKeys.PROMO]
          break
        case key === this.selectFilterKeys.PROMO:
          delete currentAction[this.selectFilterKeys.CAMPAIGN]
          break
        default:
          delete currentAction[this.selectFilterKeys.PROMO]
          delete currentAction[this.selectFilterKeys.CAMPAIGN]
      }

      currentAction[key] = value
      this.currentActions.splice(index, 1, currentAction)
      this.applyFilters()
    },
    removeAction(index) {
      this.currentActions.splice(index, 1)
      this.applyFilters()
    },
    selectCampaign(campaignId) {
      if (typeof campaignId !== 'undefined') {
        this.updateAction(this.selectedActionIndex, this.selectFilterKeys.CAMPAIGN, campaignId)
      }
      this.status = this.selectFilterStatusesV2.CHOOSE_ACTION
    },
    selectSource(source) {
      if (typeof source !== 'undefined') {
        this.updateAction(this.selectedActionIndex, this.selectFilterKeys.SOURCE, source)
      }
      this.status = this.selectFilterStatusesV2.CHOOSE_ACTION
    },
    selectPromo(promoId) {
      if (typeof promoId !== 'undefined') {
        this.updateAction(this.selectedActionIndex, this.selectFilterKeys.PROMO, promoId)
      }
      this.status = this.selectFilterStatusesV2.CHOOSE_ACTION
    },
    isCurrentStatus(status) {
      return this.status === status
    },
    getAnalyticEventType(type) {
      return this.selectAnalyticEventsV2.find((eventType) => eventType.type === type)
    },
  },
}
</script>
