<template>
  <div>
    <div role="tablist">
      <CreateCampaignStep
        step="type"
        step-number="1"
        :active="activeSteps['type']"
        :enabled="navigableSteps['type']"
        @setStep="setStep"
      >
        <template #title> Setup your campaign </template>
        <template #body>
          <CreateCampaignType />
        </template>
      </CreateCampaignStep>

      <CreateCampaignStep
        step="audience"
        step-number="2"
        :active="activeSteps['audience']"
        :enabled="navigableSteps['audience']"
        @setStep="setStep"
      >
        <template #title> Choose your audience </template>
        <template #body>
          <CreateCampaignAudience />
        </template>
      </CreateCampaignStep>

      <CreateCampaignStep
        step="message"
        step-number="3"
        :active="activeSteps['message']"
        :enabled="navigableSteps['message']"
        @saveOrUpdateCampaign="saveOrUpdateCampaign"
        @setStep="setStep"
      >
        <template #title> Write your message </template>
        <template #body>
          <CreateMessageForm :active="activeSteps['message']" />
        </template>
      </CreateCampaignStep>

      <CreateCampaignStep
        step="schedule"
        step-number="4"
        :active="activeSteps['schedule']"
        :enabled="navigableSteps['schedule']"
        @saveOrUpdateCampaign="saveOrUpdateCampaign"
        @setStep="setStep"
      >
        <template #title>
          Setup scheduling
          <span class="text-muted d-none d-sm-inline-block">(optional)</span>
        </template>
        <template #body>
          <CreateCampaignSchedule />
        </template>
      </CreateCampaignStep>

      <CreateCampaignStep
        step="summary"
        step-number="5"
        :active="activeSteps['summary']"
        :enabled="navigableSteps['summary']"
        @setStep="setStep"
      >
        <template #title> Launch your campaign </template>
        <template #body>
          <CreateCampaignSummary ref="summary" />
        </template>
      </CreateCampaignStep>
    </div>
  </div>
</template>

<script>
import CreateCampaignStep from './CreateCampaignStep'
import CreateCampaignSchedule from './CreateCampaignSchedule'
import CreateCampaignType from './CreateCampaignType'
import CreateCampaignAudience from './CreateCampaignAudience'
import CreateCampaignSummary from './CreateCampaignSummary'
import CreateMessageForm from './CreateMessageForm'
import { validationRules } from '@/lib/campaign-validation'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapMutations } = createNamespacedHelpers('campaign')
const CustomerModule = createNamespacedHelpers('customer')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CreateCampaignWrapper',
  components: {
    CreateCampaignStep,
    CreateCampaignType,
    CreateCampaignAudience,
    CreateMessageForm,
    CreateCampaignSchedule,
    CreateCampaignSummary,
  },
  props: {},
  computed: {
    ...mapState(['campaign']),
    ...CompanyModule.mapGetters(['activeCompany']),
    isActiveStepInvalid() {
      return !!validationRules[this.activeStep](this.campaign, this.$store)
    },
    activeSteps() {
      return this.steps.reduce((statuses, step, i) => {
        statuses[step] = this.activeStep === step
        return statuses
      }, {})
    },
    stepValidationMessages() {
      return this.steps.reduce((messages, step, toStepIndex) => {
        messages[step] = validationRules[step](this.campaign, this.$store)
        return messages
      }, {})
    },
    navigableSteps() {
      const activeStepIndex = this.steps.indexOf(this.activeStep)
      const furthestStepIndex = this.steps.indexOf(this.furthestStep)

      return this.steps.reduce((results, step, i) => {
        results[step] =
          // If this is the next step and the active step is valid.
          (i === activeStepIndex + 1 && !this.isActiveStepInvalid) ||
          // Or if this is a previous step.
          i <= furthestStepIndex
        return results
      }, {})
    },
  },
  data: () => ({
    steps: ['type', 'audience', 'message', 'schedule', 'summary'],
    activeStep: 'type',
    furthestStep: 'type',
    timeout: null,
  }),
  methods: {
    ...mapMutations(['resetCampaign']),
    ...CustomerModule.mapMutations(['setListFilters']),
    nextStep() {
      const i = this.steps.indexOf(this.activeStep)
      this.setStep(this.steps[i + 1])
    },
    setStep(step) {
      if (step === 'next') return this.nextStep()
      const i = this.steps.indexOf(step)
      this.activeStep = this.steps[i]
    },
    saveOrUpdateCampaign() {
      return this.$refs.summary.saveDraft()
    },
  },
  destroyed() {
    this.resetCampaign()
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.setListFilters({
        ...this.campaign.filters,
        marketingOptIn: [this.activeCompany._id],
      })
    }, 500)
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  watch: {
    isActiveStepInvalid(invalid) {
      const { activeStep } = this
      if (invalid) this.furthestStep = activeStep
    },
    activeStep(step) {
      if (this.steps.indexOf(step) > this.steps.indexOf(this.furthestStep)) {
        this.furthestStep = step
      }
    },
    campaign: {
      handler(campaign) {
        if (!campaign || !campaign._id) return
        // If we are editing allow the user to navigate to any step immediately.
        this.furthestStep = 'summary'
      },
      immediate: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>
