<template>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <div class="form-group">
          <h3 class="mt-2 mb-4">What do you want to call your campaign?</h3>
          <input
            type="text"
            ref="messageName"
            placeholder="20% off after 3pm"
            class="form-control"
            :value="selectCampaignSetup.title"
            @input="setTitle"
            v-b-popover.hover.topleft="'This is for your reference, and is not shown to customers'"
            title="Name"
          />
        </div>
      </div>
    </div>
    <div v-if="!selectCampaignSetup._id" class="row">
      <div class="col-12">
        <div class="form-group mb-0">
          <h3 class="mt-2 mb-4">What type of campaign do you want to launch?</h3>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div
                class="card"
                :class="{ active: selectCampaignSetup.type === 'auto' }"
                @click="setType('auto')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <a href="#!" class="avatar avatar-lg pt-2">
                        <span class="avatar-title rounded-circle">
                          <i class="fas fa-robot"></i>
                        </span>
                      </a>
                    </div>
                    <div class="col">
                      <!-- Title -->
                      <h4 class="card-title mb-1">
                        <a href="#!">Automated</a>
                      </h4>

                      <!-- Status -->
                      <p class="card-text text-muted small">
                        Campaign will be sent to existing and future customers.
                      </p>
                    </div>

                    <div class="col-auto" v-if="selectCampaignSetup.type === 'auto'">
                      <a href="#!" class="btn btn-sm btn-success btn-rounded-circle">
                        <span class="fe fe-check"></span>
                      </a>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <!-- / .card-body -->
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div
                class="card"
                :class="{ active: selectCampaignSetup.type === 'blast' }"
                @click="setType('blast')"
              >
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <!-- Avatar -->
                      <a href="#!" class="avatar avatar-lg pt-2">
                        <span class="avatar-title rounded-circle text">
                          <i class="fas fa-bolt"></i>
                        </span>
                      </a>
                    </div>
                    <div class="col">
                      <!-- Title -->
                      <h4 class="card-title mb-1">
                        <a href="#!">One-time blast</a>
                      </h4>

                      <!-- Status -->
                      <p class="card-text text-muted small">
                        A one-time campaign that only gets sent to existing customers.
                      </p>
                    </div>

                    <div class="col-auto">
                      <a
                        href="#!"
                        class="btn btn-sm btn-success btn-rounded-circle"
                        v-if="selectCampaignSetup.type === 'blast'"
                      >
                        <span class="fe fe-check"></span>
                      </a>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <!-- / .card-body -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'CreateCampaignSetupsType',
  data: () => ({}),
  computed: {
    ...CampaignSetupModule.mapGetters(['selectCampaignSetup']),
    invalid() {
      if (!this.selectCampaignSetup.title) {
        return 'Be sure to give your campaign a name.'
      }

      if (!this.selectCampaignSetup.type) {
        return 'Be sure to choose a campaign type.'
      }

      return false
    },
  },
  mounted() {
    this.$refs.messageName.focus()
  },
  methods: {
    ...CampaignSetupModule.mapActions(['setCampaignSetup']),
    setTitle(event) {
      this.setCampaignSetup({
        ...this.selectCampaignSetup,
        title: event.target.value,
      })
    },
    setType(type) {
      this.setCampaignSetup({
        ...this.selectCampaignSetup,
        type: type,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
}
.active {
  border-color: var(--success);
}
</style>
