<template>
  <div class="row no-gutters">
    <div class="col-3 condition">
      <button
        class="btn btn-white rounded-0 dropdown behavior-filter-btn"
        type="button"
        @click="updateAction(actionIndex, selectFilterKeys.DID, !action.did)"
        :disabled="action.eventType == this.selectAnalyticEventTypesV2.CHOOSE_ACTION"
      >
        <span class="text-primary text-capitalize">{{ actionText }}</span>
      </button>
    </div>
    <div class="col-8">
      <button
        class="btn btn-white dropdown-toggle rounded-0 action-dropdown"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="text-black">{{ activeActionLabel }}</span>
      </button>
      <div class="dropdown-menu">
        <a
          v-for="event in selectAnalyticEventsV2"
          :key="event.type"
          data-toggle="true"
          class="dropdown-item"
          href="javascript:;"
          @click="updateAction(actionIndex, selectFilterKeys.EVENT, event.type)"
          >{{ event.name }}</a
        >
      </div>
    </div>
    <div class="col-1">
      <button
        @click="removeAction(actionIndex)"
        class="btn btn-sm btn-rounded-circle btn-outline-light mt-2 pt-1 no-border"
      >
        <i class="fe fe-x text-secondary"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const ActionFilterModule = createNamespacedHelpers('actionFilter')
export default {
  name: 'AudienceActionForm',
  props: ['actionIndex', 'action', 'activeActionLabel'],
  computed: {
    actionText() {
      return this.action.did ? 'DID' : 'DID NOT'
    },
    ...ActionFilterModule.mapGetters([
      'selectAnalyticEventsV2',
      'selectFilterKeys',
      'selectAnalyticEventTypesV2',
    ]),
  },
  methods: {
    updateAction(index, key, value) {
      this.$emit('updateAction', index, key, value)
    },
    removeAction(index) {
      this.$emit('removeAction', index)
    },
  },
}
</script>

<style lang="scss" scoped>
.text-capitalize {
  text-transform: uppercase !important;
}
.condition {
  .dropdown {
    width: 89px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    height: 40px;
  }
  .behavior-filter-btn:disabled {
    border-color: #e3ebf6;
  }
}
.no-border {
  border: none;
  padding: 0;
  background: none;
}
.action-dropdown {
  height: 40px;
  span {
    display: inline-block;
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis;
  }
}
</style>
