<template>
  <div>
    <div role="tablist">
      <CreateCampaignSetupsStep
        step="type"
        step-number="1"
        :active="activeSteps['type']"
        :enabled="navigableSteps['type']"
        @setStep="setStep"
      >
        <template #title> Setup your campaign </template>
        <template #body>
          <CreateCampaignSetupsType />
        </template>
      </CreateCampaignSetupsStep>

      <CreateCampaignSetupsStep
        step="audience"
        step-number="2"
        :active="activeSteps['audience']"
        :enabled="navigableSteps['audience']"
        @setStep="setStep"
      >
        <template #title> Choose your audience </template>
        <template #body>
          <CreateCampaignSetupsAudience />
        </template>
      </CreateCampaignSetupsStep>

      <CreateCampaignSetupsStep
        step="message"
        step-number="3"
        :active="activeSteps['message']"
        :enabled="navigableSteps['message']"
        @saveOrUpdateCampaignSetup="saveOrUpdateCampaignSetup"
        @setStep="setStep"
      >
        <template #title> Write your message </template>
        <template #body>
          <CreateMessageForm :active="activeSteps['message']" />
        </template>
      </CreateCampaignSetupsStep>

      <CreateCampaignSetupsStep
        step="schedule"
        step-number="4"
        :active="activeSteps['schedule']"
        :enabled="navigableSteps['schedule']"
        @saveOrUpdateCampaignSetup="saveOrUpdateCampaignSetup"
        @setStep="setStep"
      >
        <template #title>
          Setup scheduling
          <span class="text-muted d-none d-sm-inline-block">(optional)</span>
        </template>
        <template #body>
          <CreateCampaignSetupsSchedule />
        </template>
      </CreateCampaignSetupsStep>

      <CreateCampaignSetupsStep
        step="summary"
        step-number="5"
        :active="activeSteps['summary']"
        :enabled="navigableSteps['summary']"
        @setStep="setStep"
      >
        <template #title> Launch your campaign </template>
        <template #body>
          <CreateCampaignSetupsSummary ref="summary" />
        </template>
      </CreateCampaignSetupsStep>
    </div>
  </div>
</template>

<script>
import CreateCampaignSetupsStep from './Components/CreateCampaignSetupsStep'
import CreateCampaignSetupsSchedule from './Components/CreateCampaignSetupsSchedule'
import CreateCampaignSetupsType from './Components/CreateCampaignSetupsType'
import CreateCampaignSetupsAudience from './Components/CreateCampaignSetupsAudience'
import CreateCampaignSetupsSummary from './Components/CreateCampaignSetupsSummary'
import CreateMessageForm from './Components/CreateMessageForm'
import { validationRules } from '@/lib/campaign-validation'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CustomerModule = createNamespacedHelpers('customer')

export default {
  name: 'CreateCampaignSetupsWrapper',
  components: {
    CreateCampaignSetupsStep,
    CreateCampaignSetupsType,
    CreateCampaignSetupsAudience,
    CreateMessageForm,
    CreateCampaignSetupsSchedule,
    CreateCampaignSetupsSummary,
  },
  props: {},
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    isActiveStepInvalid() {
      return !!validationRules[this.activeStep](this.selectCampaignSetup, this.$store)
    },
    activeSteps() {
      return this.steps.reduce((statuses, step, i) => {
        statuses[step] = this.activeStep === step
        return statuses
      }, {})
    },
    stepValidationMessages() {
      return this.steps.reduce((messages, step, toStepIndex) => {
        messages[step] = validationRules[step](this.selectCampaignSetup, this.$store)
        return messages
      }, {})
    },
    navigableSteps() {
      const activeStepIndex = this.steps.indexOf(this.activeStep)
      const furthestStepIndex = this.steps.indexOf(this.furthestStep)

      return this.steps.reduce((results, step, i) => {
        results[step] =
          // If this is the next step and the active step is valid.
          (i === activeStepIndex + 1 && !this.isActiveStepInvalid) ||
          // Or if this is a previous step.
          i <= furthestStepIndex
        return results
      }, {})
    },
  },
  data: () => ({
    steps: ['type', 'audience', 'message', 'schedule', 'summary'],
    activeStep: 'type',
    furthestStep: 'type',
    timeout: null,
  }),
  methods: {
    ...CampaignSetupsModule.mapActions(['resetCampaignSetup']),
    ...CustomerModule.mapActions(['setListFilters']),
    nextStep() {
      const i = this.steps.indexOf(this.activeStep)
      this.setStep(this.steps[i + 1])
    },
    setStep(step) {
      if (step === 'next') return this.nextStep()
      const i = this.steps.indexOf(step)
      this.activeStep = this.steps[i]
    },
    saveOrUpdateCampaignSetup() {
      return this.$refs.summary.saveDraft()
    },
  },
  destroyed() {
    this.resetCampaignSetup()
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.setListFilters({
        ...this.selectCampaignSetup.customFilter,
      })
    }, 500)
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },
  watch: {
    isActiveStepInvalid(invalid) {
      const { activeStep } = this
      if (invalid) this.furthestStep = activeStep
    },
    activeStep(step) {
      if (this.steps.indexOf(step) > this.steps.indexOf(this.furthestStep)) {
        this.furthestStep = step
      }
    },
    selectCampaignSetup: {
      handler(campaignSetup) {
        if (!campaignSetup || !campaignSetup._id) return
        // If we are editing allow the user to navigate to any step immediately.
        this.furthestStep = 'summary'
      },
      immediate: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>
