<template>
  <div class="table-responsive mb-0">
    <table id="customers-table" class="table table-sm table-nowrap card-table">
      <thead>
        <tr>
          <th>
            <a class="text-muted text-center" href="javascript:;"></a>
          </th>
          <th>
            <a class="text-muted" href="javascript:;">Last Activity</a>
          </th>
          <th>
            <a class="text-muted" href="javascript:;">Name</a>
          </th>
          <th class="text-center">
            <a class="text-muted" href="javascript:;">Feedback</a>
          </th>
          <th class="text-center">
            <a class="text-muted" href="javascript:;">Email</a>
          </th>
          <th class="text-center">
            <a class="text-muted" href="javascript:;">Phone</a>
          </th>
          <th v-if="selectActiveCompany._id !== 'ALL_COMPANIES'">
            <a class="text-muted" href="javascript:;">Tags</a>
          </th>
          <th v-if="loyaltyEnabled">
            <a class="text-muted" href="javascript:;">Points</a>
          </th>
        </tr>
      </thead>
      <tbody class="list">
        <tr class="table-header-message text-center" v-if="selectPreviewCustomerList.length == 0">
          <td colspan="9">No customers found. Try adjusting your filters.</td>
        </tr>
        <AudienceTableRow
          v-for="customer in selectPreviewCustomerList"
          :key="customer._id"
          :customer-data="customer"
        />
        <tr
          v-if="selectCount > selectPreviewCustomerList.length && !eventLimitReached"
          class="table-header-message text-center"
        >
          <td colspan="9">
            <button @click="loadMore" class="btn btn btn-sm btn-outline-primary">
              Load more customers
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AudienceTableRow from './AudienceTableRow'
import { createNamespacedHelpers, mapState, mapActions, mapGetters } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const CustomFilterModule = createNamespacedHelpers('customFilter')
const TagModule = createNamespacedHelpers('tag')

export default {
  name: 'AudienceTable',
  components: {
    AudienceTableRow,
  },
  computed: {
    ...CompanyModule.mapGetters(['loyaltyEnabled', 'selectActiveCompany']),
    ...CustomFilterModule.mapGetters([
      'selectPreviewCustomerList',
      'selectCount',
      'selectIsEventLimitReached',
    ]),

    eventLimitReached() {
      return this.selectIsEventLimitReached
    },
  },
  methods: {
    ...TagModule.mapActions(['getTags']),
    ...CustomFilterModule.mapActions(['fetchAudiencePreview']),
    async loadMore() {
      try {
        await Promise.all([this.fetchAudiencePreview({ loadMore: true }), this.getTags()])
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
