<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12">
        <CreateCampaignSetupsHeader @saveCampaignSetupDraft="saveDraftCampaignSetup" />
      </div>
    </div>
    <div>
      <CreateCampaignSetupsWrapper ref="campaignSetupsWrapper" />
    </div>
  </div>
</template>

<script>
import CreateCampaignSetupsHeader from './CreateCampaignSetupsHeader'
import CreateCampaignSetupsWrapper from './CreateCampaignSetupsWrapper'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'CreateCampaignSetupsView',
  components: {
    CreateCampaignSetupsHeader,
    CreateCampaignSetupsWrapper,
  },
  mounted() {
    this.getUserLocationsByActiveCompany()
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    saveDraftCampaignSetup() {
      return this.$refs.campaignSetupsWrapper.saveOrUpdateCampaignSetup()
    },
  },
}
</script>
