<template>
  <div class="row justify-content-center">
    <div class="col=12" v-if="!selectCampaignSetup.type">
      <div class="alert alert-light mt-3" role="alert">
        After completing step one, you'll see some useful settings appear here.
      </div>
    </div>
    <div class="col-12 col-lg-10 col-xl-8">
      <div class="form-text text-muted mb-4" v-if="selectCampaignSetup.type === 'auto'">
        If customers match this campaign outside of allowed SMS sending hours, messages will be
        queued and will resume the following business day.
      </div>
      <div v-if="selectCampaignSetup.type == 'auto'" class="row justify-content-center">
        <!-- Delay -->
        <div class="col-12 col-lg-6">
          <!-- Public profile -->
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">Delay messages</label>
            <!-- Form text -->
            <small class="form-text text-muted">
              Instead of sending a message immediately once a customer matches your audience, you
              can setup a delay.
            </small>
            <div class="row">
              <div class="col-auto">
                <!-- Switch -->
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    class="custom-control-input"
                    id="enableDelayToggle"
                    v-model="delayToggle"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="enableDelayToggle"></label>
                </div>
              </div>
              <div class="col ml-n2">
                <!-- Help text -->
                <small class="text-muted" v-if="!delayToggle">
                  Messages will send immediately
                </small>

                <!-- Help text -->
                <small class="text-muted" v-else> Message will be delayed </small>
              </div>
            </div>
            <!-- / .row -->
            <hr v-if="delayToggle" />
            <div class="row col-12" v-if="delayToggle">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <span class="fe fe-clock mr-1"></span>Minutes to delay
                  </div>
                </div>
                <input
                  type="text"
                  :value="selectCampaignSetup.autoSettings.matchDelay"
                  @keyup="setDelay($event)"
                  class="form-control form-control-prepended"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- End Date -->
        <div class="col-12 col-lg-6">
          <!-- Allow for additional Bookings -->
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">Automatically end campaign</label>
            <!-- Form text -->
            <small class="form-text text-muted">
              Choose a date to stop sending new messages from this campaign on a future date.
            </small>
            <div class="row">
              <div class="col-auto">
                <!-- Switch -->
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    class="custom-control-input"
                    id="endDateToggle"
                    v-model="endToggle"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="endDateToggle"></label>
                </div>
              </div>
              <div class="col ml-n2">
                <!-- Help text -->
                <small class="text-muted" v-if="!endToggle">Campaign will run indefinitely</small>

                <!-- Help text -->
                <small class="text-muted" v-else>Campaign will end at the specified date.</small>
              </div>
            </div>
            <!-- / .row -->
            <hr v-if="endToggle" />
            <div class="row col-12" v-if="endToggle">
              <flat-pickr
                :value="selectCampaignSetup.autoSettings.endDate"
                @input="setEndDate"
                class="form-control"
                :config="{
                  mode: 'single',
                  enableTime: false,
                  altInput: true,
                  allowInput: true,
                  dateFormat: 'Z',
                  altFormat: 'M j, Y h:i K',
                  wrap: true,
                  key: 'autoEndDate',
                  minDate: $moment().toDate(),
                  minTime: minTime,
                  maxTime: maxTime,
                }"
                placeholder="Choose a date"
                name="autoEndDate"
              ></flat-pickr>
            </div>
          </div>
        </div>
      </div>

      <!-- blast -->
      <div v-if="selectCampaignSetup.type == 'blast'" class="row justify-content-center">
        <!-- Start Date -->
        <div class="col-12">
          <!-- Allow for additional Bookings -->
          <div class="form-group">
            <!-- Label -->
            <label class="mb-1">Campaign scheduling</label>

            <!-- Form text -->
            <small class="form-text text-muted">
              {{
                disableBlastScheduleToggle
                  ? 'Unable to send immediately, please select a date in the future. According to TCPA regulations campaigns can only be sent out during the hours of 8:00 AM and 9:00 PM.'
                  : 'Select a date and time to send out your campaign. (Leave blank to send immediately)'
              }}
            </small>
            <div class="row">
              <div class="col-auto">
                <!-- Switch -->
                <div class="custom-control custom-checkbox-toggle">
                  <input
                    class="custom-control-input"
                    id="blastScheduleToggle"
                    :disabled="disableBlastScheduleToggle"
                    v-model="blastToggle"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="blastScheduleToggle"></label>
                </div>
              </div>
              <div class="col ml-n2">
                <!-- Help text -->
                <small class="text-muted" v-if="!blastToggle">
                  Messages will be sent immediately
                </small>

                <!-- Help text -->
                <small class="text-muted" v-else>
                  Messages will be sent at the selected time in {{ userTimezone.abbr }}
                </small>
              </div>
            </div>
            <!-- / .row -->
            <hr v-if="blastToggle" />
            <div class="row col-12" v-if="blastToggle">
              <flat-pickr
                ref="sinceRef"
                :value="selectCampaignSetup.startDate"
                class="form-control"
                :config="{
                  mode: 'single',
                  enableTime: true,
                  time_24hr: false,
                  altInput: true,
                  allowInput: true,
                  dateFormat: 'Z',
                  altFormat: 'M j, Y h:i K',
                  wrap: true,
                  key: 'blastScheduleDate',
                  minDate: $moment().toDate(),
                  minTime: minTime,
                  maxTime: maxTime,
                }"
                placeholder="Choose a date"
                name="blastScheduleDate"
                @input="setStartDate"
              ></flat-pickr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')

export default {
  name: 'CreateCampaignSetupsSchedule',
  data() {
    return {
      minTime: '08:00',
      maxTime: '20:59',
      disableBlastScheduleToggle: false,
      abbrs: {
        EST: 'Eastern Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
      },
      delayToggle: false,
      endToggle: false,
      blastToggle: false,
    }
  },
  mounted() {
    if (
      !this.$moment().isBetween(
        this.$moment(this.minTime, 'hh:mm'),
        this.$moment(this.maxTime, 'hh:mm')
      )
    ) {
      this.disableBlastScheduleToggle = true
      this.blastToggle = true
    }
  },
  computed: {
    ...CampaignSetupsModule.mapGetters(['selectCampaignSetup']),
    userTimezone() {
      const zoneName = this.$moment.tz.guess()
      const zoneAbbr = this.$moment.tz(zoneName).zoneAbbr()
      return {
        abbr: this.abbrs[zoneAbbr] || zoneAbbr,
        zoneName: zoneName,
      }
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions(['setCampaignSetup', 'validateBlastSchedule']),
    updateCampaignSetup(updatedField) {
      this.setCampaignSetup({
        ...this.selectCampaignSetup,
        ...updatedField,
      })
    },
    setDelay(event) {
      this.updateCampaignSetup({
        autoSettings: {
          ...this.selectCampaignSetup.autoSettings,
          matchDelay: event.target.value,
        },
      })
    },
    setEndDate(endDate) {
      this.updateCampaignSetup({
        autoSettings: {
          ...this.selectCampaignSetup.autoSettings,
          endDate,
        },
      })
    },
    setStartDate(startDate) {
      this.updateCampaignSetup({ startDate })
      if (this.selectCampaignSetup.type === 'blast') {
        this.validateBlastSchedule(this.blastToggle)
      }
    },
  },
  watch: {
    selectCampaignSetup: {
      immediate: true,
      handler() {
        if (this.selectCampaignSetup.autoSettings.matchDelay) {
          this.delayToggle = true
        }
        if (this.selectCampaignSetup.autoSettings.endDate) {
          this.endToggle = true
        }
        if (
          this.selectCampaignSetup.type === 'blast' &&
          this.selectCampaignSetup.startDate &&
          this.$moment(this.selectCampaignSetup.startDate).isAfter(this.$moment())
        ) {
          this.blastToggle = true
        }
      },
    },
    blastToggle: {
      immediate: true,
      handler() {
        this.validateBlastSchedule(this.blastToggle)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
}
input.form-control {
  height: calc(2.40625rem + 3px);
}
</style>
