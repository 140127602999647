<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="header-pretitle">Take Action</h6>
          <h1 class="header-title">{{ campaign._id ? 'Edit' : 'Create' }} Campaign</h1>
        </div>
        <div
          v-if="
            (!campaign._id && campaign.messageBody) ||
            (campaign._id && campaign.messageBody && campaign.status == campaignStatus.DRAFT)
          "
        >
          <a
            @click="saveCampaignDraft"
            href="javascript:;"
            class="btn btn-primary mr-3"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
            >Save draft</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { campaignStatus } from '@/lib/modules/campaign'

const CampaignModule = createNamespacedHelpers('campaign')

export default {
  name: 'CreateCampaignHeader',
  data: () => ({
    isLoading: false,
    campaignStatus: campaignStatus,
  }),
  computed: {
    ...CampaignModule.mapState(['campaign']),
  },
  methods: {
    saveCampaignDraft() {
      this.$emit('saveCampaignDraft')
    },
  },
}
</script>

<style scoped lang="scss"></style>
